<template>
  <div>
    <!-- header -->
    <div class="path-header">
      <p class="path">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="item in pathList" :key="item.id" @click.native="handleClickPath(item)">
            <el-tooltip effect="dark" :enterable="false" :content="item.label" placement="top">
              <span class="path-item">{{ item.label }}</span>
            </el-tooltip>
          </el-breadcrumb-item>
        </el-breadcrumb>
        <el-button v-if="pathList.length !== 1" type="primary" size="mini" style="margin-left: 24px" @click="handleBack"
          >返回上一级</el-button
        >
      </p>
      <div class="right">
        <div class="action">
          <!-- TODO:check -->
          <el-button
            v-if="isLastLevel && $check('env-archives_upload')"
            style="margin-right: 10px"
            type="primary"
            @click="$refs.uploadDialogRef.open()"
            >上传</el-button
          >
          <span v-if="showType === 'FileContentList'">
            <el-button
              v-if="hasFile && $check('env-archives_download')"
              :loading="downloading"
              @click="handleDownload"
              >{{ downText }}</el-button
            >
            <el-button v-if="hasFile && $check('env-archives_info')" @click="handleDetail">查看详情</el-button>
            <el-button v-if="hasFile && $check('env-archives_delete')" type="danger" @click="handleDelete"
              >删除</el-button
            >
          </span>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="toggle">
          <i class="iconfont icon-pailie1" style="margin-right: 8px;" @click="toggleShowType('FileContentList')"></i>
          <i class="iconfont icon-pailie2" @click="toggleShowType('FileContentFlat')"></i>
        </div>
      </div>
    </div>
    <!-- content -->
    <div class="content" style="padding-left: 16px">
      <component
        :is="showType"
        :file-list="fileList"
        :loading="loading"
        :folderId="pathList[pathList.length - 1].id"
        @on-click="handleClickFile"
        @on-dblclick="handleDblclick"
        @on-refresh="getFileListData(pathList[pathList.length - 1].id)"
      ></component>
    </div>
    <!-- 上传文件选择弹窗 -->
    <UploadDialog ref="uploadDialogRef" :folderId="pathList[pathList.length - 1].id" @add-success="onUploadSuccess" />
    <!-- 详情 -->
    <detail-dialog ref="detailDialogRef"></detail-dialog>
  </div>
</template>

<script>
import downloadFileFunc from '@/utils/download-file.js';
import DetailDialog from '@/views/pages/general-user/security-pages/internal-data/data-list/detailDialog.vue';
import UploadDialog from '@/components/Upload/UploadDialog.vue';
import FileContentList from '@/components/FileContentList/index.vue';
import FileContentFlat from '@/components/FileContentFlat/index.vue';

import { getFileList, deleteFile, downloadFile } from '@/api/general-user/internal-data.js';

export default {
  components: { DetailDialog, UploadDialog, FileContentList, FileContentFlat },
  data() {
    return {
      showType: 'FileContentList',
      isLastLevel: false,
      loading: false,
      pathList: [{ id: '0', label: '全部文件' }],
      fileList: [],
      hasFile: false,
      currentCheckedFileInfo: {},
      downloading: false,
      downText: '下载'
    };
  },
  created() {
    this.getFileListData();
  },
  methods: {
    toggleShowType(type) {
      this.showType = type;
    },
    async getFileListData(folderId = '0') {
      try {
        this.loading = true;
        const {
          data: { fileList, folderList }
        } = await getFileList({ folderId });
        this.isLastLevel = !folderList.length;
        if (folderList.length) {
          folderList.forEach(item => {
            item.fileType = 1; // 文件夹
          });
        }
        if (fileList.length) {
          fileList.forEach(item => {
            item.fileType = 2; // 文件
          });
        }
        this.hasFile = fileList.length;
        this.fileList = [...folderList, ...fileList];
        this.currentCheckedFileInfo = {};
      } finally {
        this.loading = false;
      }
    },
    // 双击进入下一级
    handleDblclick(row) {
      this.getFileListData(row.id).then(() => {
        this.pathList.push({ id: row.id, label: row.folderName });
      });
    },
    handleClickPath(item) {
      this.getFileListData(item.id).then(() => {
        const { pathList } = this;
        const index = pathList.findIndex(ele => ele.id === item.id);
        this.pathList = pathList.slice(0, index + 1);
      });
    },
    handleBack() {
      this.getFileListData(this.pathList[this.pathList.length - 2].id).then(() => {
        const { pathList } = this;
        this.pathList = pathList.slice(0, pathList.length - 1);
      });
    },
    onUploadSuccess(folderId) {
      this.getFileListData(folderId);
    },
    handleClickFile(row) {
      if (row.fileType === 2) {
        this.currentCheckedFileInfo = row;
      } else if (row.fileType === 1) {
        this.currentCheckedFileInfo = {};
      }
    },
    handleDelete() {
      if (!this.currentCheckedFileInfo.fileId) {
        return this.$message.info('请选择要删除的文件！');
      }
      const currentFolderId = this.pathList[this.pathList.length - 1].id;
      this.$confirm('是否确认删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = {
            folderId: '',
            fileIds: []
          };
          params.folderId = currentFolderId;
          params.fileIds.push(this.currentCheckedFileInfo.fileId);
          return deleteFile(params);
        })
        .then(res => {
          this.$message.success('删除文件成功！');
          this.getFileListData(currentFolderId);
        });
    },
    handleDownload() {
      if (!this.currentCheckedFileInfo.fileId) {
        return this.$message.info('请选择要下载的文件！');
      }
      this.downloading = true;
      this.downText = '下载中...';
      downloadFile({ fileId: this.currentCheckedFileInfo.fileId })
        .then(res => {
          downloadFileFunc(res);
        })
        .finally(() => {
          this.downloading = false;
          this.downText = '下载';
        });
    },
    handleDetail() {
      if (!this.currentCheckedFileInfo.fileId) {
        return this.$message.info('请选择要查看的文件！');
      }
      this.$refs.detailDialogRef.open(this.currentCheckedFileInfo);
    }
  }
};
</script>

<style lang="less" scoped>
.iconfont {
  cursor: pointer;
  font-weight: 700;
}
.path-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 56px;
  background: #f3f6fe;
  font-size: 14px;
  color: #979fa8;

  .path {
    display: flex;
    align-items: center;

    .path-item {
      display: inline-block;
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &:hover {
        color: #005aab;
        font-weight: 500;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .action {
      margin-right: 16px;
    }
  }
}

/deep/ .el-table th.el-table__cell.is-leaf {
  color: #303133 !important;
}
</style>
